import { keyframes } from '@emotion/react'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50%  {
    opacity:1;
  }
  100% {
    opacity:1;
  }
`

const variantName = 'contentBoxesV6'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto',
      position: 'relative'
    },
    // ?======================
    // ?======  TABS =========
    // ?======================

    boxTabs: {
      display: 'flex',
      flexDirection: ['row', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: ['flex-start', '', 'center'],
      alignItems: 'stretch',
      p: ['1.5rem 0rem', '', '1.5rem'],
      width: '100%',
      backdropFilter: 'blur(10px)',
      position: 'absolute',
      bottom: '0rem',
      '.swiper-slide': {
        width: ['75%', '', '100%']
      }
    },
    boxTab: {
      width: ['100%'],
      height: '100%',
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden'
    },
    boxTabActive: {
      width: ['100%'],
      height: '100%',
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: 'secondary'
    },

    boxTabImageContainer: {
      width: '33.3%',
      height: '110%',
      display: 'flex'
    },
    boxTabImage: {
      objectFit: 'cover'
    },

    boxTabTextContent: {
      width: '66.6%',
      padding: ['0.5rem', '', '1rem'],
      display: 'flex',
      flexDirection: 'column'
    },

    boxTabTitle: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: ['0.85rem', '', '1rem']
    },

    boxTabSubtitle: {
      color: 'white',
      fontSize: ['0.85rem', '', '1rem']
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================

    boxes: {
      pb: '100px'
    },
    box: {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      width: '100%',
      padding: '5%',
      height: '85vh',
      animation: `${fadeIn} 2.5s linear`,
      animationFillMode: 'forwards'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageContainer: {
      width: ['100%', '', '100%'],
      height: ['100%', '', '100%'],
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },

    image: {
      marginBottom: 1,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      filter: 'brightness(0.8)'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    content: {
      width: '100%',
      padding: '5%',
      maxWidth: '1000px',
      zIndex: '100',
      position: 'absolute',
      top: ['50%', '', '45%'],
      left: '50%',
      transform: 'translate(-50%,-50%)',
      color: 'white',
      textAlign: ['left', '', 'center'],
      alignItems: ['flex-start', '', 'center'],
      '.linksContainer': {
        justifyContent: ['flex-start', '', 'center'],
        mt: '2rem'
      }
    },

    date: {
      marginBottom: 1,
      fontSize: 2,
      paddingX: 2,
      fontWeight: 'bold'
    },
    title: {
      variant: 'text.title',
      marginBottom: 4
    },
    subtitle: {
      variant: 'text.subtitle',
      marginBottom: 4
    },
    text: {
      variant: 'text.text',
      marginBottom: 8
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
