export default {
  heading: 'Playball, cursive',
  subheading: 'Noto Sans Display',
  body: 'Rajdhani, sans-serif',
  body2: 'Rajdhani, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Rajdhani:300,400,500,600,700',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Playball'
  ]
  // customFamilies: ['Lindsey W01 Regular'],
  // customUrls: ['https://www.gonation.biz/fonts/lindsey-w01-regular/lindsey-w01-regular.css']
}
